<template>
    <NebulaPageWrapper
        class="employee-dashboard-container"
        padded
    >
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12" class="error__row">
                <img class="error__figure" :src="image">
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="6" :offset="3" :mdSize="8" :mdOffset="0" :smOffset="0" class="error__row">
                <h1 class="error__title">
                    {{ title }}
                </h1>
                <p>
                    {{ message }}
                </p>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn class="error__row" :columns="6" :offset="3" :mdSize="8" :mdOffset="0" :smOffset="0">
                <NebulaButton v-if="primaryButton" v-bind="primaryButton" />
                <NebulaButton :text="$t('help-link-text', { ns: 'help' })" type="flat" :link="helpLink" />
            </NebulaColumn>
        </NebulaGridRow>
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid, NebulaButton } from '@discoveryedu/nebula-components';

import { educatorRoles } from '@/constants';

const meiStressed = require('@/assets/mei-stressed.png');
const zoeConcerned = require('@/assets/zoe-concerned.png');

export default {
    name: 'Error',
    components: {
        NebulaButton,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        error() {
            return this.$store.state.app.error;
        },
        title() {
            return this.error.title || 'Something went wrong';
        },
        message() {
            return this.error.message || this.error;
        },
        primaryButton() {
            return this.error.primaryButton;
        },
        image() {
            return this[this.error.image] || meiStressed;
        },
        helpLink() {
            if (educatorRoles.includes(this.appMode)) {
                return this.$t('help-link-educators', { ns: 'help' });
            }
            return this.$t('help-link-employees', { ns: 'help' });
        },
    },
    data() {
        return {
            meiStressed,
            zoeConcerned,
        };
    },
    mounted() {
        // if (!this.$store.state.app.error) {
        //     this.$router.push('/');
        // }
    },
};
</script>

<style lang="stylus">
.error {
    &__figure {
        margin-block-end: $nebula-space-2x;
        margin-block-start: $nebula-space-10x;
        width: 100%;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: 411px;
        }
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: $nebula-space-2x;
    }

    &__title {
        flex-basis: 100%;
        font-size: $nebula-font-size-header-level-4;
        margin: 0;
        text-align: center;
    }
}
</style>
